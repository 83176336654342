@import './default.scss';

.footer {
  background-color: #333;
  padding: 25px;
  color: white;
  margin: 0 auto;
}

.ocg-logo {
  background: url('../content/images/OverclockedCornerLogo.png');
  background-size: 300px 145px;
  height: 145px;
  width: 300px;
  display:block;
}

@media (max-width: 650px) {
  .ocg-logo {
    background-size: 100px 50px;
    height: 50px;
    width: 100px;
  }
}