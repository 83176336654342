@import './default.scss';
$header-height: 80px;

.header-image {
  background: url('../content/images/TextLogo_YS200.png');
  background-repeat: no-repeat;
  background-size: 700px 131px;
  height: 131px;
  width: 700px;
  margin: 2px auto 16px auto;
  padding-top: 100px;
  display: block;
  pointer-events: all; /* Pointer events are blocked in the parent appbar to prevent conflicts */
}

.toggle-button {
  margin-top: 15px;
  background: url('../content/images/Icon_Nav120.png');
  background-size: 45px 45px;
  height: 45px;
  width: 45px;
  background-repeat: no-repeat;
  display: block;
  border: 0;
  float: right;
  cursor: pointer;
}

.toggle-button:active, .toggle-button:focus {
  background-color: transparent !important;
}

.toggled-button {
  margin-top: 15px;
  background: url('../content/images/Icon_NavRollOver120.png');
  background-size: 45px 45px;
  height: 45px;
  width: 45px;
  background-repeat: no-repeat;
  display: block;
  border: 0;
  float: right;
  cursor: pointer;
}

.toggled-button:hover {
  background-color: transparent;
}

.toggled-button:active, .toggled-button:focus {
  background-color: transparent !important;
}

.main-nav {
  background-repeat:no-repeat;
  background-position: center center;
  background-color: #222;
  border-color: #080808;
  color: white;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem;
  margin: 0 auto;
}

.full-menu-section {
  text-align: center;
  font-size: 2.0rem;
  background: no-repeat center;
  color: white;
}

.full-menu-section:active, .full-menu-section:focus, .full-menu-section:hover, .full-menu-section:link {
    background-color: transparent !important;
    color: white;
    text-decoration: none;
}

.play-button-large {
  background: url('../content/images/Icon_Download120.png');
  background-size: 60px 60px;
  background-repeat: no-repeat;
  margin: 2px auto 16px auto;
  display: block;
  height: 60px;
  width: 60px;
}

.store-button-large {
  background: url('../content/images/donate-small.png');
  background-size: 120px 71px;
  background-repeat: no-repeat;
  margin: 2px auto 2px auto;
  display: block;
  height: 71px;
  width: 120px;
}

.scrolls-button-large {
  background: url('../content/images/Icon_DevBlog120.png');
  background-size: 60px 60px;
  background-repeat: no-repeat;
  margin: 2px auto 16px auto;
  display: block;
  height: 60px;
  width: 60px;
}

.explore-button-large {
  background: url('../content/images/Icon_MapCharItem120.png');
  background-size: 60px 60px;
  background-repeat: no-repeat;
  margin: 2px auto 16px auto;
  display: block;
  height: 60px;
  width: 60px;
} 

@media (max-width: 770px) {
  .main-nav {
      background-repeat: repeat-y;
      background-color: #222;
      border-color: #080808;
      color: white;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: .5rem 1rem;
      max-width: 1600px;
      margin: 0 auto;
  }

  .small-menu {
    display: none!important; 
  }

  .header-image {
      background: url('../content/images/TextLogo_YS200.png');
      background-repeat: no-repeat;
      background-size: 300px 65px;
      height: 65px;
      width: 300px;
      margin: 2px auto 16px auto;
      padding-top: 50px;
      padding-bottom: 50px;
      display: block;
  }

  .play-button-large {
      background: url('../content/images/Icon_Download120.png');
      background-size: 40px 40px;
      background-repeat: no-repeat;
      margin: 2px auto 8px auto;
      display: block;
      height: 40px;
      width: 40px;
  }
    
  .store-button-large {
      background: url('../content/images/donate-small.png');
      background-size: 60px 40px;
      background-repeat: no-repeat;
      margin: 2px auto 8px auto;
      display: block;
      height: 40px;
      width: 60px;
  }
  
  .scrolls-button-large {
      background: url('../content/images/Icon_DevBlog120.png');
      background-size: 40px 40px;
      background-repeat: no-repeat;
      margin: 2px auto 8px auto;
      display: block;
      height: 40px;
      width: 40px;
  }
  
  .explore-button-large {
      background: url('../content/images/Icon_MapCharItem120.png');
      background-size: 40px 40px;
      background-repeat: no-repeat;
      margin: 2px auto 8px auto;
      display: block;
      height: 40px;
      width: 40px;
  }

  .full-menu-section {
      text-align: center;
      font-size: 1.0rem;
      background: no-repeat center;
      color: white;
    }
  
  .full-menu-subtitle {
      display: none;
  }

  .social-icon-name {
      display: none;
  }
}

@media (max-width: 450px) {
  .header-image {
      background: url('../content/images/TextLogo_YS200.png');
      background-repeat: no-repeat;
      background-size: 200px 40px;
      height: 40px;
      width: 200px;
      margin: 20px auto auto auto;
      display: block;
  }

  .icon span {
      margin-left: 0px !important;
    }

    .toggle-button {
      margin-top: 5px;
      background: url('../content/images/Icon_Nav120.png');
      background-size: 30px 30px;
      height: 30px;
      width: 30px;
      background-repeat: no-repeat;
      display: block;
      border: 0;
      float: right;
      cursor: pointer;
  }
  
  .toggled-button {
      margin-top: 5px;
      background: url('../content/images/Icon_NavRollOver120.png');
      background-size: 30px 30px;
      height: 30px;
      width: 30px;
      background-repeat: no-repeat;
      display: block;
      border: 0;
      float: right;
      cursor: pointer;
  }
}

.spin {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  100% { transform: rotate(360deg); }
}

.icon {
  position: relative;
  top: 15px;
  list-style-type: none;
}

.icon a {
  color: rgba(255,255,255,.5);
}

.icon span {
  margin-left: 10px;
}