@import './default.scss';

.banner-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: url("../content/images/YokaiImageHeader.jpg") no-repeat center / cover;
  background-attachment: fixed;
}
.banner-text-wrapper {
  position: absolute;
  left: 13%;
  top: 33%;
  color: #fff;
  text-align: right;
  font-family: $font-family;
}
.banner-text-wrapper h2 {
  font-size: 80px;
  font-weight: normal;
  white-space: nowrap;
  color: rgb(255, 0, 0);
  margin-bottom: 8px;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
}
.banner-text-wrapper h2 p {
  font-size: 70px;
  color: #ffffff;
  display: inline-block;
  margin: 0;
  font-family: 'Roboto';
}

.banner-text-wrapper .line {
  width: 1.8px;
  height: 176px;
  position: absolute;
  background: rgba(255, 255, 255, .44);
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  top: 16px;
  right: -25px;
}

.banner-text-wrapper > p {
  margin: 10px auto 24px;
  -webkit-text-fill-color: black;
  -webkit-text-stroke: 1px whitesmoke;
}

.banner-text-wrapper .start-button {
  -webkit-text-stroke: 1px black;
  $start-button-color: tint($primary-color, 20%);
  margin-bottom: 24px;
  text-align: right;
  > a {
    display: inline-block;
    height: 40px;
    padding: 0 16px;
    font-weight: 500;
    border-radius: $border-radius-base;
    border: 1px solid $start-button-color;
    text-decoration: none;
    color: #0ae;
    font-size: 15px;
    background: transparent;
    transition: all .45s;
    text-align: center;
    line-height: 36px;
    margin-left: 8px;
    &:hover {
      color: #fff;
      background: $start-button-color;
    }
  }
}

.down {
  text-align: center;
  bottom: 30px;
  color: rgba(255, 255, 255, .75);
  left: 50%;
  margin-left: -7px;
  animation: upDownMove 1.2s ease-in-out infinite alternate;
  box-shadow: 0 0px 20px whitesmoke;
}

.page {
  min-height: 500px;
  height: 50vh;
}

.art-page {
  background: #fff;
}

.world-page {
  background: #fff;
}

.capture-yokai-page {
  background: url("../content/images/washi-background.png") no-repeat center / cover;
}

.characters-page {
  background: url("../content/images/washi-background.png") no-repeat center / cover;
}

.combat-page {
  background: #fff;
}

.craft-page {
  background: #fff;
}

.defend-page {
  background: url("../content/images/washi-background.png") no-repeat center / cover;
}

.story-page {
  background: url("../content/images/BG_YokaiParadeDark.jpg") no-repeat center / cover;
}

.platforms-page {
  background: #fff;
}

.community-page {
  background: #fff;
  padding-left:5%;
  padding-right:5%;
}

.content-wrapper {
  width: 100%;
  margin: auto;
  padding-top:1%;
  padding-bottom:1%;
  overflow: hidden; // Prevents pages from showing content that go outside of its bounds
  
  .image-wrapper {
    width: 100%;
    float: left;
  }

  .text-wrapper {
    width: 80%;
    float: left;
    font-family: $font-family;
    z-index: 1;
  }
  .text-wrapper h2,
  .text-wrapper-bottom h2 {
    font-size: 32px;
    color: #999;
    font-weight: normal;
    white-space: nowrap;
  }
  .text-wrapper p {
    margin: 10px 0 20px;
    font-size: 16px;
    line-height: 28px;
    color: #999;
  }
  .text-wrapper-center {
    text-align: center;
    width: 100%;
  }
  .text-wrapper-center h1 {
    color: black;
    text-align: center;
    width: 100%;
  }
}

.banner-platforms-image {
  background: url("../content/images/platforms.png") no-repeat center / contain;
  min-height: 120px;
  margin: auto;
}
.art-image {
  background: url("../content/images/day-night-cycle.gif") no-repeat left / contain;
  background-origin: content-box;
  min-height: 480px;
  padding-left: 1%;
  background-position:right;
}
.world-image {
  background: url("../content/images/overworld-map.png") no-repeat left / contain;
  background-origin: content-box;
  min-height: 500px;
  background-position:right;
}
.learn-image {
  background: url("../content/images/learn-banner.png") no-repeat left / contain;
  background-origin: content-box;
  min-height: 500px;
  background-position:right;
}
.capture-yokai-image {
  background: url("../content/images/yokai/rokurokubi.gif") no-repeat left / contain;
  background-origin: content-box;
  min-height: 500px;
  padding-left: 1%;
  background-position:right;
}
.story-image {
  background: url("../content/images/storybook.gif") no-repeat left / contain;
  background-origin: content-box;
  min-height: 426px;
  padding-left: 1%;
  background-position:right;
}
.combat-image {
  background: url("../content/images/combat-banner.png") no-repeat left / contain;
  background-origin: content-box;
  min-height: 500px;
  background-position:right;
}
.characters-image {
  background: url("../content/images/characters-banner.png") no-repeat left / contain;
  background-origin: content-box;
  min-height: 500px;
  background-position:right;
}
.crafting-image {
  background: url("../content/images/crafting-banner.png") no-repeat left / contain;
  background-origin: content-box;
  min-height: 500px;
  background-position:right;
}
.defend-image {
  background: url("../content/images/defend-banner.png") no-repeat left / contain;
  background-origin: content-box;
  min-height: 500px;
  background-position:right;
}
.community-image {
  background: url("../content/images/contact.png") no-repeat right / contain;
  min-height: 250px;
  background-position:left;
}


@keyframes upDownMove {
  to {
    transform: translateY(10px);
  }
}