$border-color: rgba(229, 231, 235, 100);
$border-color-base: hsv(0, 0, 85%);
$padding-space: 144px;
$site-text-color: #314659;

$text-color: fade(black, 85%);
$primary-color: blue-6;
$border-radius-base: 2px;

@font-face {
    font-family: 'DFKaiSB';
    src: url('../content/fonts/dfkai-sb.eot'); /* IE9 Compat Modes */
    src: url('../content/fonts/dfkai-sb.ttf')  format('truetype'), /* Safari, Android, iOS */
        url('../content/fonts/dfkai-sb.woff') format('woff');
    font-weight: bold;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

$font-family: 'DFKaiSB';