@import "./default.scss";

  /* custom css for devices where the primary input mechanism cannot hover 
  at all or cannot conveniently hover */
@media (hover: none) { 
  // iOS and other mobile devices do not support background-attachment:fixed (parallax scrolling)
  .banner-wrapper {
    background-attachment:scroll;
  }
}

@media only screen and (min-width: 0) and (max-width: 992px) {

  .banner-entry {
    position: relative;
    top: 30px;
    left: 0;
    text-align: center;
  }

  .image-wrapper {
    min-height: 225px;
    background-position: center;
    margin: auto;
  }

  .banner-wrapper {
    background-position: 40%;
  }

  .content-wrapper {
    padding-left: 5%;
    padding-right: 5%;
  }
  .content-wrapper .text-wrapper {
    width: 100%;
  }

  .content-wrapper .text-wrapper p {
    font-size: 14px;
    line-height: 20px;
    color: #999;
  }

  .content-wrapper .text-wrapper h2,
  .text-wrapper-bottom h2 {
    font-size: 22px;
    color: $text-color;
    font-weight: normal;
    white-space: nowrap;
  }

  .banner-platforms-image {
    background: url("../content/images/platforms.png") no-repeat center / contain;
    min-height: 80px;
    margin: auto;
  }
  .capture-yokai-image {
    background: url("../content/images/yokai/rokurokubi.gif") no-repeat center / cover;
    background-origin: content-box;
    min-height: 300px;
    background-position:right;
  }

  .banner-text-wrapper {
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    .start-button {
      text-align: center;
      > a {
        margin: 0 4px;
      }
    }
    .line {
      display: none;
    }
  }
}